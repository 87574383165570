import { Routes, Route } from "react-router-dom";
import HomePage from "./page/Home";
import ProductShrinkDetails from "./page/ProductDetails/Shrink";
import ProductLabelDetails from "./page/ProductDetails/Label";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      {/* ======= Header ======= */}
      <Header />
      {/* End Header */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product-shrink" element={<ProductShrinkDetails />} />
        <Route path="/product-label" element={<ProductLabelDetails />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      {/* ======= Footer ======= */}
      <Footer />
      {/* End Footer */}
    </>
  );
}

export default App;
