/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>
                <img alt="" src="assets/img/logo.png" />
              </h3>
              <p>
                Aji GIDC Estate, <br />
                Shed No C1B-237/2 Road U,
                <br />
                7RJC+597 Rajkot, Gujarat <br />
                <br />
                <strong>Phone:</strong> +91 98981 11003 +91 93774 55500
                <br />
                <strong>Email:</strong> shreeshrink@gmail.com
                <br />
              </p>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              {/* <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#about">About us</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#services">Services</a>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-3 col-md-6 footer-links"></div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p>
                Stay connected and informed through our vibrant social media
                presence
              </p>
              <div className="social-links mt-3">
                <a href="#" className="twitter">
                  <i className="bx bxl-twitter" />
                </a>
                <a href="#" className="facebook">
                  <i className="bx bxl-facebook" />
                </a>
                <a href="#" className="instagram">
                  <i className="bx bxl-instagram" />
                </a>
                {/* <a href="#" className="google-plus">
                  <i className="bx bxl-skype" />
                </a>
                <a href="#" className="linkedin">
                  <i className="bx bxl-linkedin" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>SP</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
