import React, { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import Contact from "../../components/Contact";
import Portfolio from "../../components/Portfolio";

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  return isLoading ? (
    <div id="preloader" />
  ) : (
    <>
      {/* ======= Hero Section ======= */}
      <HeroSection />
      {/* End Hero */}
      <main id="main">
        {/* ======= Clients Section ======= */}
        <section id="clients" className="clients section-bg">
          <div className="container">
            <div className="row" data-aos="zoom-in">
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* End Cliens Section */}
        {/* ======= About Us Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
            <div className="row content">
              <div className="col-lg-6">
                <p>
                  SHREE POLYSHRINK, with over two decades of expertise,
                  specializes in PVC SHRINK FILM and PRINTED LABELS. Our focus
                  on quality and innovation, exemplified by products like
                  High-Performance PVC Shrink Film for 10-color printing,
                  distinguishes us in the market.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line" /> Over 20 years of
                    experience as a prominent Manufacturer and Exporter
                    specializing in PVC SHRINK FILM and PRINTED LABELS.
                  </li>
                  <li>
                    <i className="ri-check-double-line" /> Dedicated to
                    delivering statuesque quality consistently, leveraging
                    state-of-the-art facilities and standardized processes.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  Backed by 20+ years of experience, our team at SHREE
                  POLYSHRINK drives continuous innovation. With a commitment to
                  excellence, we deliver premium solutions that meet evolving
                  industry needs, setting new standards in the field.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line" /> Emphasis on
                    innovation and creation, with a team of specialized
                    operators and management professionals driving the
                    development of pioneering products like the High-Performance
                    PVC Shrink Film, capable of accommodating 10-color printing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* End About Us Section */}
        {/* ======= Why Us Section ======= */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                <div className="content">
                  <h3>
                    <strong>E </strong>xperience
                    <strong> E</strong>xcellence
                  </h3>
                  <p>
                    Our Commitment to Innovation and Client Success"
                    {/* Quick answers to questions you may have. Can't find what
                  you're looking for? */}
                  </p>
                </div>
                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#accordion-list-1"
                      >
                        <span>01</span> Industry Expertise{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          With over two decades of experience in the packaging
                          industry, we bring unparalleled expertise to every
                          project. Our seasoned professionals understand the
                          nuances of packaging design and production, ensuring
                          that each solution meets the highest standards of
                          quality and functionality.
                        </p>
                      </div>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> Innovative Solutions{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          We pride ourselves on our ability to innovate and push
                          the boundaries of packaging design. From pioneering
                          materials to cutting-edge printing techniques, we
                          continuously seek out new ways to elevate your brand
                          and captivate consumers.
                        </p>
                      </div>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> Client-Centric Approach
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          At the heart of everything we do is a commitment to
                          our clients' success. We take the time to understand
                          your unique needs and goals, working collaboratively
                          to deliver solutions that not only meet but exceed
                          your expectations. With personalized service and
                          attention to detail, we strive to be a trusted partner
                          in your brand's journey.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                style={{ backgroundImage: 'url("assets/img/why-us.png")' }}
                data-aos="zoom-in"
                data-aos-delay={150}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </section>
        {/* End Why Us Section */}
        {/* ======= Services Section ======= */}
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Services</h2>
              <p>
                Discover the essence of convenience and satisfaction. Our
                solutions address your needs, propelling you forward while
                eliminating obstacles. Embrace the freedom to pursue your
                desires unhindered, as our services seamlessly cater to your
                aspirations and ambitions, creating an environment of effortless
                productivity
              </p>
            </div>
            <div className="row">
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble" />
                  </div>
                  <h4>
                    <a href="">Custom Printing Services</a>
                  </h4>
                  <p>
                    Elevate your brand with vibrant and customizable printing
                    options for PVC Shrink Film and Labels.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-file" />
                  </div>
                  <h4>
                    <a href="">Consultation and Design Expertise</a>
                  </h4>
                  <p>
                    Benefit from our industry-leading professionals' guidance to
                    optimize your packaging strategies.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-tachometer" />
                  </div>
                  <h4>
                    <a href="">Global Sourcing Network</a>
                  </h4>
                  <p>
                    Access a reliable and diverse supply chain for all your PVC
                    shrink film needs, ensuring consistent quality.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
                data-aos="zoom-in"
                data-aos-delay={400}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-layer" />
                  </div>
                  <h4>
                    <a href="">Quality Assurance and Traceability</a>
                  </h4>
                  <p>
                    Rest assured with our stringent quality control measures and
                    transparent traceability standards throughout production.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Cta Section ======= */}
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-start">
                <h3>Call To Action</h3>
                <p>
                  {" "}
                  Unlock the potential of your packaging with our tailored
                  solutions. Don't let opportunities pass by – take charge of
                  your brand's success. Explore our services now and elevate
                  your packaging game to new heights.
                </p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a className="cta-btn align-middle" href="#contact">
                  Call To Action
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Cta Section */}
        {/* ======= Portfolio Section ======= */}
        <Portfolio />
        {/* End Portfolio Section */}

        {/* ======= Frequently Asked Questions Section ======= */}
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <p>
                Find answers to common queries about our products, services, and
                processes in our comprehensive FAQ section. Whether you're
                curious about our packaging solutions, printing capabilities, or
                quality assurance measures, we've got you covered. Explore our
                FAQ to gain clarity and confidence in partnering with us for
                your packaging needs.
              </p>
            </div>
            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What types of packaging solutions do you offer?{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      We specialize in a variety of packaging solutions,
                      including PVC shrink film, shrink labels, tamper-evident
                      bands, and multi-packs, catering to diverse industry
                      needs.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    Can you accommodate custom printing requests?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Absolutely! We offer custom printing services tailored to
                      your specific requirements, ensuring your branding and
                      messaging are accurately represented on your packaging.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={300}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    How do you ensure the quality of your products?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Our products undergo rigorous quality control measures,
                      adhering to the strictest standards and regulations. We
                      manufacture in controlled environments and maintain
                      traceability throughout the production process to
                      guarantee quality and consistency.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={400}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    What printing capabilities do you have?{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Our printing capabilities include flexographic and digital
                      printing presses, allowing us to print up to 10 colors
                      with precision and clarity, ensuring your packaging stands
                      out on the shelf.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={500}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    Do you offer environmentally friendly packaging options?
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, we are committed to sustainability. We offer
                      environmentally friendly PVC shrink film options crafted
                      from modified ingredients, providing eco-conscious
                      packaging solutions without compromising quality or
                      performance.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Frequently Asked Questions Section */}
        {/* ======= Contact Section ======= */}
        <Contact />
        {/* End Contact Section */}
      </main>
      {/* End #main */}
    </>
  );
};

export default HomePage;
