import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductShrinkDetails = () => {
  return (
    <>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Portfolio Details</li>
            </ol>
            <h2>PVC SHRINK FILM</h2>
          </div>
        </section>
        {/* End Breadcrumbs */}
        {/* ======= Portfolio Details Section ======= */}
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-8">
                <div className="portfolio-details-slider swiper">
                  <Carousel
                    showArrows={true}
                    autoPlay
                    dynamicHeight
                    infiniteLoop
                  >
                    <div>
                      <img
                        src="assets/img/portfolio/shrink-1.jpeg"
                        alt="PVC SHRINK FILM"
                      />
                      {/* <p className="legend">Product 1</p> */}
                    </div>
                    <div>
                      <img
                        src="assets/img/portfolio/shrink-2.webp"
                        alt="PVC SHRINK FILM"
                      />
                      {/* <p className="legend">Product 2</p> */}
                    </div>
                    <div>
                      <img
                        src="assets/img/portfolio/shrink-3.jpeg"
                        alt="PVC SHRINK FILM"
                      />
                      {/* <p className="legend">Product 2</p> */}
                    </div>
                  </Carousel>
                  <div className="swiper-pagination" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-info">
                  <h2>Product Details</h2>
                  <p>
                    We know that innovative packaging helps to sell your
                    product. The most economical and popular packaging material
                    for making Shrink Labels. Easy to print; stable quality and
                    shrinkage with good transparency. PVC Shrink Label Film is a
                    good choice for all industries and containers. This
                    fast-growing technology is ideal for launching new or
                    re-designed products. We can offer environmentally friendly
                    PVC Shrink Label Film based on our own modified ingredients.
                    High Performance PVC Shrink Film which can be used for 10
                    color printing.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-description">
                  <h2>Key Points:</h2>
                  <ul>
                    <li>
                      Shrink -Label Films can cover the entire body of your
                      product. So you can print more information and more
                      intricate designs.
                    </li>
                    <li>
                      The print quality is outstanding. You can reverse print to
                      protect labels from scratching and wear while maintaining
                      a high-gloss finish.
                    </li>
                    <li>
                      Shrink films work well with unusually shaped containers
                      that distinguish your product on the shelf.
                    </li>
                    <li>
                      Full body shrink labels can screen the product inside and
                      allow you to create easy opening tamper-evident seals.
                    </li>
                    <li>
                      With our global sourcing you can always get the shrink
                      film supply you need.
                    </li>
                    <li>
                      Our films are manufactured in a controlled environment to
                      the strictest current GMP standards and are absolutely
                      traceable.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-description">
                  <h2>APPLICATIONS:</h2>
                  <ul>
                    <li>Full or Partial body Shrink Sleeves</li>
                    <li>Full or Partial body Roll Sleeves</li>
                    <li>Multi-packs</li>
                    <li>Tamper Evident bands</li>
                    <li>Wine capsules and Disk Plates</li>
                    <li>Pressure-sensitives Shrink Labels</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Details Section */}
      </main>
      {/* End #main */}
    </>
  );
};

export default ProductShrinkDetails;
