import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [path, setPath] = useState("hero");

  return (
    <header id="header" className="fixed-top ">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <Link to="/">
            <img alt="" src="assets/img/logo.png" />
          </Link>
        </h1>
        {/* Uncomment below if you prefer to use an image logo */}
        {/* <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
        <nav id="navbar" className="navbar">
          <ul>
            <li
              onClick={() => {
                setPath("hero");
              }}
            >
              <a
                className={`nav-link scrollto ${
                  path === "hero" ? "active" : ""
                }`}
                href="#hero"
              >
                Home
              </a>
            </li>
            <li
              onClick={() => {
                setPath("about");
              }}
            >
              <a
                className={`nav-link scrollto ${
                  path === "about" ? "active" : ""
                }`}
                href="#about"
              >
                About
              </a>
            </li>
            <li
              onClick={() => {
                setPath("service");
              }}
            >
              <a
                className={`nav-link scrollto ${
                  path === "service" ? "active" : ""
                }`}
                href="#services"
              >
                Services
              </a>
            </li>
            <li
              onClick={() => {
                setPath("portfolio");
              }}
            >
              <a
                className={`nav-link scrollto ${
                  path === "portfolio" ? "active" : ""
                }`}
                href="#portfolio"
              >
                Portfolio
              </a>
            </li>
            <li
              onClick={() => {
                setPath("contact");
              }}
            >
              <a
                className={`nav-link scrollto ${
                  path === "contact" ? "active" : ""
                }`}
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" />
        </nav>
        {/* .navbar */}
      </div>
    </header>
  );
};

export default Header;
