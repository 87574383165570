import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductLabelDetails = () => {
  return (
    <>
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Portfolio Details</li>
            </ol>
            <h2>PVC SHRINK LABEL</h2>
          </div>
        </section>
        {/* End Breadcrumbs */}
        {/* ======= Portfolio Details Section ======= */}
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-8">
                <div className="portfolio-details-slider swiper">
                  <Carousel
                    showArrows={true}
                    autoPlay
                    dynamicHeight
                    infiniteLoop
                  >
                    <div>
                      <img src="assets/img/portfolio/label-1.webp" />
                      {/* <p className="legend">Product 1</p> */}
                    </div>
                    <div>
                      <img src="assets/img/portfolio/label-2.webp" />
                      {/* <p className="legend">Product 2</p> */}
                    </div>
                    <div>
                      <img src="assets/img/portfolio/label-3.jpg" />
                      {/* <p className="legend">Product 2</p> */}
                    </div>
                  </Carousel>
                  <div className="swiper-pagination" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-info">
                  <h2>Product Details</h2>
                  <p>
                    PVC Heat Shrink sleeves are made to cover a product either
                    in part or in full. They are reverse printed and seamed
                    using clear or color, PVC materials. With flexographic and
                    digital printing presses, Our sleeve printing capabilities
                    includes up to 10 colors.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-description">
                  <h2>Key Points:</h2>
                  <ul>
                    <li>
                      With our colorful and Eye Catching sleeve it provides
                      enhancement to its looks, also they can be made to the
                      contour of any size container.
                    </li>
                    <li>
                      Includes features like: Scuff resistant, Quality graphics,
                      No label look and Tamper Evident packaging.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="portfolio-description">
                  <h2>APPLICATIONS:</h2>
                  <ul>
                    <li>
                      We provide labels for all industries and all types of
                      containers.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Details Section */}
      </main>
      {/* End #main */}
    </>
  );
};

export default ProductLabelDetails;
