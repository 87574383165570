import React, { useState } from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [tab, setTab] = useState("all");

  const handleTab = (tabName) => {
    setTab(tabName);
  };

  return (
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>
            Explore our portfolio showcasing a diverse range of innovative
            packaging solutions tailored to meet the unique needs of our
            clients. From vibrant shrink labels to custom-printed film, each
            project exemplifies our commitment to excellence and creativity.
            Dive into our portfolio to discover how we've helped brands like
            yours stand out on the shelves and leave a lasting impression.
          </p>
        </div>
        <ul
          id="portfolio-flters"
          className="d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <li
            data-filter="*"
            className={tab === "all" ? "filter-active" : ""}
            onClick={() => handleTab("all")}
          >
            All
          </li>
          <li
            data-filter=".filter-app"
            className={tab === "label" ? "filter-active" : ""}
            onClick={() => handleTab("label")}
          >
            Label
          </li>
          <li
            data-filter=".filter-card"
            className={tab === "film" ? "filter-active" : ""}
            onClick={() => handleTab("film")}
          >
            Film
          </li>
        </ul>
        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          {(tab === "all" || tab === "label") && (
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-img">
                <Link to={"/product-label"}>
                  <img
                    src="assets/img/portfolio/label-2.webp"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4>PVC SHRINK LABEL</h4>
                <p>It's sleeves are made to cover a product.</p>
                <Link
                  to={"/product-label"}
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link" />
                </Link>
              </div>
            </div>
          )}

          {(tab === "all" || tab === "film") && (
            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-img">
                <Link to={"/product-shrink"}>
                  <img
                    src="assets/img/portfolio/shrink-1.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
              <div className="portfolio-info">
                <h4>PVC SHRINK FILM</h4>
                <p>It's an innovative packaging.</p>
                <Link
                  to={"/product-shrink"}
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
